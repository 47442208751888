/**
 * import
 *
 * @version 0.1 (2021.06.25 : usayama)
 */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IoMailOutline } from 'react-icons/io5'
import { useForm } from 'react-hook-form'
// conf, action
import { APP_ROUTE } from '@config/routers'
import { VALIDATION_PATTERNS } from '@config/validation.patterns'
import authActions from '@redux/auth/actions'
// components
import InputGroup from '@components/Form/InputGroup'
import Button from '@components/UIKit/Button'
import LinkButton from '@components/UIKit/LinkButton'
// containers
// helper
// import { isEmpty } from '@lib/helpers'
// style, img, elm
// const
const { initForgotPassword, forgotPassword } = authActions

/** 
 * export : 
 * 
 * @version 0.1 (2021.06.25 : usayama_dx)
*/
export default function ForgotPasswordContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.25 : usayama)
   */
  const { handleSubmit, 
          register, 
          formState,
          setError,
          errors } = useForm({ mode: 'onChange' })
  const dispatch = useDispatch()
  const { forgotPasswordResult } = useSelector(state => state.Auth)

  /** 
   * useState : 
   * 
   * @version 0.1 (2021.06.25 : usayama_dx)
   */
  const [ initPage, setInitPage ] = React.useState(false)

  /** 
   * event : 
   * 
   * @version 0.1 (2021.06.25 : usayama)
   */
  const onSubmit = (d) => dispatch(forgotPassword(d))

  /** 
   * useEffect
   * 
   * @version 0.1 (2021.06.25 : usayama)
   */
  React.useEffect(() => {
    if(forgotPasswordResult && forgotPasswordResult.errors) {
      Object.keys(forgotPasswordResult.errors).map((key) => setError(key, { type: "manual", message: forgotPasswordResult.errors[key] }))
    }
  }, [forgotPasswordResult, setError])

  /** 
   * componentDidMount : https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.06.25 : usayama)
   */
  React.useEffect(() => {
    if(!initPage) {
      dispatch(initForgotPassword())
      setInitPage(true)
    }
  }, [initPage, dispatch])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.06.25 : usayama_dx)
   */
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup
          label={<IoMailOutline size={25} />}
          placeholder="placeholder.email"
          type="email"
          name="email"
          feedback={errors && errors.email}
          errors={errors && errors.email}
          className={errors.email && "is-invalid"}
          inputClassName={errors.email && "is-invalid"}
          inputRef={register({ required: true, pattern: VALIDATION_PATTERNS.email })}
         />
        <div className="form-action">
          <Button type="submit" color="success" label="forgot_password" disabled={!formState.isValid} />
        </div>
      </form>
      <hr />
      <LinkButton to={APP_ROUTE.login} label="text.to_login" />
    </div>
  )
}
