/**
 * import
 *
 * @version 0.1 (2021.06.25 : usayama)
 * @version 0.2 (2021.06.28 : usayama) // Seo 追加
 */
import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// conf, action
// components
import Seo from '@components/Tools/Seo'
import ModalLoading from '@components/UIKit/ModalLoading'
import Alert from '@components/UIKit/Alert'
import LabelHtml from '@components/Typo/LabelHtml'
// containers
import LayoutContainer from '@containers/LayoutContainer'
import AuthContainer from '@containers/AuthContainer'
import ForgotPasswordContainer from '@containers/ForgotPasswordContainer'
// helper
// style, img, elm
// const

/**
 * const
 *
 * @version 0.1 (2021.06.25 : usayama)
 */
const ForgotPassword = () => {


	/**
	 * const
	 *
	 * @version 0.1 (2021.06.25 : usayama)
	*/
	const { t } = useTranslation()
  const { loadForgotPassword, forgotPasswordResultStatus } = useSelector(state => state.Auth)

	/**
	 * return
	 *
	 * @version 0.1 (2021.06.25 : usayama)
	 * @version 0.2 (2021.06.28 : usayama) // Seo 追加
	 */
	return (
	    <LayoutContainer>
	    	<Seo title={t('title.forgot_password')} />
				<ModalLoading isLoading={loadForgotPassword} />
      	<AuthContainer titleLabel="title.forgot_password">
      		{ forgotPasswordResultStatus && <Alert status={forgotPasswordResultStatus} label={"message." + forgotPasswordResultStatus + ".forgot_password"} isOpen={true} closeBt={true} /> }
      		{ forgotPasswordResultStatus !== "success" && <p className="mb-4"><LabelHtml label="text.forgot_password" /></p> }
      		{ forgotPasswordResultStatus !== "success" && <ForgotPasswordContainer /> }
      	</AuthContainer>
	    </LayoutContainer>
	)
}

/**
 * export
 *
 * @version 0.1 (2021.06.25 : usayama)
 */
export default ForgotPassword
